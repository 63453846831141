<script lang="ts">
  import type { Config } from './types';

  interface Props {
    config: Config;
    children?: import('svelte').Snippet;
  }

  let { config, children }: Props = $props();
</script>

<div class="container">
  <div class="backdrop"></div>
  <div class="popup" class:white-label={config.whiteLabel}>
    <div class="content">
      {@render children?.()}
    </div>
    <a class="powered-by" target="_blank" href="https://heyflow.app?ref=cookie-consent">
      <img
        alt="Heyflow"
        src="https://storage.googleapis.com/heyflow-eu-static/logos/heyflow-smile.png"
      />
      <span class="text">Privacy by Heyflow</span>
    </a>
  </div>
</div>

<style>
  .container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: var(--hey-backdrop-opacity);
  }

  .popup {
    font-size: var(--hey-font-size);
    background-color: white;
    max-width: 600px;
    max-height: calc(100vh - 80px);
    box-sizing: border-box;
    border-radius: 0.5em;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 20px;
  }


  .content {
    padding: 2em;
    max-height: calc(100% - 36px);
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 1;
  }


  .powered-by {
    text-decoration: none;
    padding: 10px 0;
    font-size: 0.95em;
    color: rgba(0, 0, 0, 0.5);
    background: #f2f2f5;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 7.5px;
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .powered-by:hover {
    text-decoration: underline;
  }

  .powered-by img {
    width: 16px;
  }

  .popup.white-label .content {
    max-height: 100%;
  }
  .popup.white-label .powered-by {
    display: none;
  }

  @media screen and (max-width: 400px) {
    .content {
      padding: 1em;
    }
  }
</style>
