import { mount } from 'svelte';

import App from './App.svelte';
import type { Config } from './types';
import { enableScripts, getConfirmed, sendConsentModeUpdate } from './utils';

const CONFIG_VAR = 'HEYFLOW_COOKIE_CONSENT_CONFIG';

function applyCssVarDefault(
  div: HTMLElement,
  varName: string,
  defaultValue: string,
) {
  div.style.setProperty(
    varName,
    getComputedStyle(div).getPropertyValue(varName).trim() || defaultValue,
  );
}

if (!window[CONFIG_VAR]) {
  console.error(`${CONFIG_VAR} not defined`);
} else {
  const config = window[CONFIG_VAR] as Config;
  const key = `heyflow-cookie-consent/${config.domain ?? location.host}`;

  const div = document.getElementById('heyflow-cookie-consent');

  if (div === null) {
    throw Error('Element with id "heyflow-cookie-consent" not found');
  }

  applyCssVarDefault(div, '--hey-accent-color', '#3f51ff');
  applyCssVarDefault(div, '--hey-font-family', 'Helvetica, arial, sans-serif');
  applyCssVarDefault(div, '--hey-font-size', '14px');
  applyCssVarDefault(div, '--hey-z-index', '1001');
  applyCssVarDefault(div, '--hey-backdrop-opacity', '0.7');

  const choices = getConfirmed(key);
  mount(App, {
    target: div,
    props: {
      config,
      key,
      show: choices === null,
    },
  });
  if (choices !== null) {
    sendConsentModeUpdate(choices);
    enableScripts(choices);
  }
}
