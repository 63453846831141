<script lang="ts">
  import { createBubbler, stopPropagation } from 'svelte/legacy';

  const bubble = createBubbler();
  import ChevronIcon from './icons/ChevronIcon.svelte';

  interface Props {
    title: string;
    action?: import('svelte').Snippet;
    children?: import('svelte').Snippet;
  }

  let { title, action, children }: Props = $props();

  let open = $state(false);
</script>

<div class="header" class:active={open}>
  <div class="title" class:active={open} onclick={() => (open = !open)}>
    <div class="chevron-icon">
      <ChevronIcon />
    </div>
    <div>{title}</div>
  </div>
  <div class="action" onclick={stopPropagation(bubble('click'))}>
    {@render action?.()}
  </div>
</div>
{#if open}
  <div class="body">
    {@render children?.()}
  </div>
{/if}

<style>
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0px solid #eaeaea;
    min-height: 42px;
    padding-right: 12px;
  }

  .header.active {
    background: #fafafa;
  }

  .title {
    color: #1a1a1a;
    font-size: 1em;
    display: flex;
    column-gap: 10px;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    user-select: none;
    cursor: pointer;
    padding: 12px;
  }

  .title:hover {
    text-decoration: underline;
  }

  .header .chevron-icon {
    transition: transform 0.2s;
  }

  .header.active .chevron-icon {
    transform: rotate(90deg);
  }

  .header:hover {
    background: #fafafa;
  }

  .action {
    text-align: right;
  }

  .body {
    background-color: #fafafa;
    color: #2a2a2a;
    padding: 12px;
    padding-top: 0;
    border-bottom: 1px solid #eaeaea;
  }
</style>
